/** @module components/Dashboard */
import React from 'react';
import { Layout } from 'antd';
import {
  Redirect,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';
import queryString from 'query-string';
import { appPaths, AppRouteParams } from 'utilities/routes';
import { isAdmin } from 'utilities/admin';
import SearchResultsPage from 'components/SearchResultsPage';
import DashboardPageError from 'components/DashboardPageError';
import { ErrorType } from 'components/DashboardPageError/types';
import UserSearchResultsPage from 'components/UserSearchResultsPage';
import ManageAdminRolesPage from 'components/ManageAdminRolesPage';
import ManageRepositoryPage from 'components/ManageRepositoryPage';
import ManageSnapshotsPage from 'components/ManageSnapshotsPage';
import ManageCollaboratorsPage, { AdminManageCollaborator } from 'components/ManageCollaboratorsPage';
import NewRepositoryPage from 'components/NewRepositoryPage';
import DeleteUserPage from 'components/DeleteUserPage';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import Drawer from '../Drawer';
import DefinitionsPage from '../DefinitionsPage';
import Header from '../Header';
import Notifier from '../Notifier';
import RepositoriesPage from '../RepositoriesPage';
import MyRepositoriesTrashPage from '../MyRepositoriesTrashPage';
import SharedRepositoriesTrashPage from '../SharedRepositoriesTrashPage';
import RepositoryItemsPage from '../RepositoryItemsPage';
import RepositoryItemsTrashPage from '../RepositoryItemsTrashPage';
import ReassignRepositoriesPage from '../ReassignRepositoriesPage';
import AdminDashboard from '../AdminDashboard';
import AdminReassignRepositoriesPage from '../AdminReassignRepositoriesPage';
import ReleaseNotesPage from '../ReleaseNotesPage';
import DomainsPage from '../DomainsPage';
import UploadQueue from '../UploadQueue';
import UploadProgress from '../UploadProgress';
import { Props, State } from './types';
import './styles.scss';

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: localStorage.getItem('navigationCollapsed') === 'true',
    };
  }

  onCollapse(collapsed: boolean): void {
    localStorage.setItem('navigationCollapsed', collapsed.toString());
    this.setState({ collapsed });
  }

  /**
   * Returns true if the given user owns the given repository.
   *
   * @return true if the given user owns the given repository
   */
  isOwner(): boolean {
    const {
      repository,
      me,
    } = this.props;
    if (
      repository
      && me.user
      && repository.owner.id === me.user.id
    ) {
      return true;
    }
    return false;
  }

  redirectByOwner(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element | undefined {
    const { repository } = this.props;
    const { basePath } = routeProps.match.params;
    const pathArray = routeProps.location.pathname.split('/');
    // The user is visiting my-repositories when they do not own the repository
    if (repository && basePath === 'my-repositories' && !this.isOwner()) {
      pathArray[1] = 'shared-repositories';
      return <Redirect to={`${pathArray.join('/')}${routeProps.location.search}`} data-test-id="redirect-my-to-shared" />;
    }
    // The user is visiting shared-repositories when they own the repository
    if (repository && basePath === 'shared-repositories' && this.isOwner()) {
      pathArray[1] = 'my-repositories';
      return <Redirect to={`${pathArray.join('/')}${routeProps.location.search}`} data-test-id="redirect-shared-to-my" />;
    }
    return undefined;
  }

  render(): JSX.Element {
    const { collapsed } = this.state;
    const { me, adminConsoleEnabled, searchEnabled } = this.props;
    const { Content } = Layout;
    let style = 'Dashboard__content';
    if (collapsed) {
      style += ' collapsed';
    }
    const redirectPath = localStorage.getItem('fromPath');
    if (redirectPath) {
      localStorage.removeItem('fromPath');
      // direct download link path
      if (redirectPath.includes('/metadata')) {
        // download the file
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = redirectPath;
        a.setAttribute('download', 'download');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        return <Redirect to={redirectPath} data-test-id="redirect-to-fromPath" />;
      }
    }

    return (
      <>
        <Header />
        <Drawer
          collapsed={collapsed}
          onCollapse={(c: boolean): void => this.onCollapse(c)}
          me={me}
          data-test-id="drawer"
        />
        <Notifier />
        <Content
          className={style}
          data-test-id="content"
        >
          <Switch>
            {adminConsoleEnabled && isAdmin(me)
              && [
                <Route
                  exact
                  path={appPaths.admin}
                  component={AdminDashboard}
                  data-test-id="route-admin-console"
                  key="route-admin-console"
                />,
                <Route
                  exact
                  path={appPaths.adminUserSearch}
                  component={UserSearchResultsPage}
                  data-test-id="route-admin-user-search"
                  key="route-admin-user-search"
                />,
                <Route
                  exact
                  path={appPaths.adminManageAdminRoles}
                  component={ManageAdminRolesPage}
                  data-test-id="route-admin-manage-roles"
                  key="route-admin-manage-roles"
                />,
                <Route
                  exact
                  path={appPaths.adminReassignRepositories}
                  render={(): JSX.Element => {
                    if (me.permissions) {
                      if (me.permissions.global
                        .includes(GlobalPermissions.GLOBAL_UPDATE_COLLABORATOR)
                      ) {
                        return (
                          <AdminReassignRepositoriesPage
                            data-test-id="admin-reassign-repositories-page"
                          />
                        );
                      }
                      // eslint-disable-next-line consistent-return
                      me.permissions.byBusiness.forEach((byBusiness) => {
                        if (byBusiness.permissions
                          .includes(BusinessPermissions.BUSINESS_UPDATE_COLLABORATOR)
                        ) {
                          return (
                            <AdminReassignRepositoriesPage
                              data-test-id="admin-reassign-repositories-page"
                            />
                          );
                        }
                      });
                    }
                    return <Redirect to="/admin" data-test-id="admin" />;
                  }}
                  data-test-id="route-admin-reassign-repositories"
                  key="route-admin-reassign-repositories"
                />,
                <Route
                  exact
                  path={appPaths.adminDeleteUser}
                  render={(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element => (
                    <DeleteUserPage
                      userId={routeProps.match.params.userId || ''}
                      data-test-id="delete-user-page"
                    />
                  )}
                  data-test-id="route-delete-user"
                  key="route-delete-user"
                />,
              ]}

            <Route
              exact
              path="/"
              render={(): JSX.Element => (
                me.user && me.user.external
                  ? <Redirect to="/shared-repositories" data-test-id="redirect-shared-repositories" />
                  : <Redirect to="/my-repositories" data-test-id="redirect-my-repositories" />
              )}
              data-test-id="route-root"
            />

            <Route
              path={appPaths.repositories}
              render={(routeParams): JSX.Element => {
                const pathArray = routeParams.location.pathname.split('/');
                pathArray[1] = this.isOwner() ? 'my-repositories' : 'shared-repositories';
                const url = `${pathArray.join('/')}${routeParams.location.search}`;
                return <Redirect to={url} data-test-id="redirect-repositories" />;
              }}
              data-test-id="route-repositories"
            />
            <Route
              exact
              path={appPaths.myRepositories}
              component={RepositoriesPage}
            />
            <Route
              exact
              path={appPaths.sharedRepositories}
              component={RepositoriesPage}
            />
            <Route
              exact
              path={appPaths.myRepositoriesTrash}
              component={MyRepositoriesTrashPage}
            />
            <Route
              exact
              path={appPaths.sharedRepositoriesTrash}
              component={SharedRepositoriesTrashPage}
            />
            <Route
              exact
              path={appPaths.reassignRepositories}
              component={ReassignRepositoriesPage}
            />

            <Route
              exact
              path={appPaths.manageCollaborators}
              render={(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element => (
                <ManageCollaboratorsPage
                  repositoryId={routeProps.match.params.repoId || ''}
                  data-test-id="manage-collaborators-page"
                />
              )}
              data-test-id="route-mange-collab"
            />

            <Route
              exact
              path={appPaths.manageRepository}
              render={(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element => (
                <ManageRepositoryPage
                  repositoryId={routeProps.match.params.repoId || ''}
                  data-test-id="manage-repo-page"
                />
              )}
              data-test-id="route-manage-repo"
            />

            <Route
              exact
              path={appPaths.manageSnapshots}
              render={(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element => (
                <ManageSnapshotsPage
                  repositoryId={routeProps.match.params.repoId || ''}
                  data-test-id="manage-snapshot-page"
                />
              )}
              data-test-id="route-manage-snapshot"
            />

            <Route
              exact
              path={appPaths.adminManageCollaborators}
              component={AdminManageCollaborator}
            />

            <Route
              exact
              path={appPaths.repositoryTrash}
              render={(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element => {
                const redirect = this.redirectByOwner(routeProps);
                if (redirect) {
                  return redirect;
                }
                return (
                  <RepositoryItemsTrashPage
                    basePath={routeProps.match.params.basePath || ''}
                    repositoryId={routeProps.match.params.repoId || ''}
                    data-test-id="repository-items-trash-page"
                  />
                );
              }}
              data-test-id="route-repository-trash"
            />
            <Route
              exact
              path={[appPaths.repository, appPaths.folder]}
              render={(routeProps: RouteComponentProps<AppRouteParams>): JSX.Element => {
                const redirect = this.redirectByOwner(routeProps);
                if (redirect) {
                  return redirect;
                }
                let previewQuery = queryString.parse(routeProps.location.search).preview;
                if (typeof previewQuery !== 'string') {
                  previewQuery = undefined;
                }
                return (
                  <RepositoryItemsPage
                    basePath={routeProps.match.params.basePath || ''}
                    repositoryId={routeProps.match.params.repoId || ''}
                    folderId={routeProps.match.params.folderId}
                    previewId={previewQuery}
                    data-test-id="repository-items-page"
                  />
                );
              }}
              data-test-id="route-folder"
            />
            <Route
              exact
              path={appPaths.definitions}
              component={DefinitionsPage}
            />
            <Route
              exact
              path={appPaths.releaseNotes}
              component={ReleaseNotesPage}
            />
            <Route
              exact
              path={appPaths.domains}
              component={DomainsPage}
            />
            <Route
              exact
              path={appPaths.newRepository}
              component={NewRepositoryPage}
            />

            {searchEnabled && (
              <Route
                exact
                path={appPaths.searchResults}
                component={SearchResultsPage}
              />
            )}

            <Route
              path="*"
              render={(): JSX.Element => <DashboardPageError errorType={ErrorType.NOT_FOUND} data-test-id="not-found" />}
              data-test-id="route-wildcard"
            />
          </Switch>

          <UploadQueue concurrentUploads={3} />
          <UploadProgress />
        </Content>
      </>
    );
  }
}

export default Dashboard;
